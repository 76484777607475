<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <h4 class="mb-2">{{ $t("products.products") }}</h4>
        <button
          class="btn w-100 text-start"
          v-on:click="
            active = 'categories';
            type = 'products';
          "
          :class="
            active === 'categories' && type === 'products'
              ? 'btn-primary'
              : 'btn-simple'
          "
        >
          {{ $t("products.categories") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="
            active = 'characteristics';
            type = 'products';
          "
          :class="
            active === 'characteristics' && type === 'products'
              ? 'btn-primary'
              : 'btn-simple'
          "
        >
          {{ $t("products.characteristics") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="
            active = 'measurements';
            type = 'products';
          "
          :class="
            active === 'measurements' && type === 'products'
              ? 'btn-primary'
              : 'btn-simple'
          "
        >
          {{ $t("products.measurements") }}
        </button>
        <h4 class="mb-2">{{ $t("products.materials") }}</h4>
        <button
          class="btn w-100 text-start"
          v-on:click="
            active = 'categories';
            type = 'materials';
          "
          :class="
            active === 'categories' && type === 'materials'
              ? 'btn-primary'
              : 'btn-simple'
          "
        >
          {{ $t("products.categories") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="
            active = 'characteristics';
            type = 'materials';
          "
          :class="
            active === 'characteristics' && type === 'materials'
              ? 'btn-primary'
              : 'btn-simple'
          "
        >
          {{ $t("products.characteristics") }}
        </button>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10">
      <h2>{{ $t("products.products") }}</h2>
      <Categories
        v-if="active === 'categories'"
        v-model:selected-language="selectedLanguage"
        v-model:active-languages="activeLanguages"
        v-model:type="type"
      />
      <div v-if="active === 'characteristics'">
        <h4>{{ $t("products.characteristics") }}</h4>
        <OneByOneTable
          v-model:rows="characteristicsRows"
          v-model:items="characteristics"
          type="characteristics"
          :show-edit="true"
          v-on:edit="characteristicsModal"
          v-on:addbtn="characteristicsModal"
          v-on:delete="deleteCharacteristicsModal"
        />
      </div>
      <div v-if="active === 'measurements'">
        <div class="row mb-3 align-items-center">
          <div class="col">
            <h4>{{ $t("products.measurements") }}</h4>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-type-4 btn-small"
              v-on:click="newMeasurementsModal"
            >
              <span>{{ $t("products.addNewGroup") }}</span>
            </button>
          </div>
        </div>
        <div class="mb-3 line-between-rows">
          <div
            class="row mb-3"
            v-for="measurement in measurements"
            :key="measurement.name"
          >
            <div class="col-12">
              <h5 class="bold-16">{{ measurement.name }}</h5>
              <OneByOneTable
                v-model:rows="measurement.units"
                v-model:items="measurement.conversions"
                type="unit"
                :empty-line="true"
                v-on:addbtn="() => newMeasurementUnitModal(measurement.id)"
                v-on:update:field="measurementUpdate"
                v-on:add:field="(e, f) => measurementAdd(measurement.id, e, f)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CharacteristicsModal
    ref="addCharactericsModal"
    :edit-characteristics="editCharacteristics"
    :type="type"
    v-on:change="characteristicsChange"
  />
  <div
    ref="newMeasurementsModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("products.addNewGroup") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row row-tabs">
            <div
              class="col-auto p-0 m-0"
              v-for="l in activeLanguages"
              :key="l.code"
            >
              <button
                class="btn btn-tab"
                :class="{ active: l.code === selectedLanguage }"
                v-on:click="selectedLanguage = l.code"
              >
                <span class="semibold-14">{{ l.name }}</span>
              </button>
            </div>
          </div>
          <div class="text-start m-2 custom-input">
            <div class="row">
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("products.groupName")
                }}</label>
                <input
                  class="form-control"
                  v-model="NewMeasurement.name[selectedLanguage]"
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("products.groupDescription") }}
                </label>
                <WYSIWYG
                  v-model="NewMeasurement.description[selectedLanguage]"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="saveNewMeasurement"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("orderChange.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    ref="newMeasurementUnitModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("products.addNewUnit") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start m-2 custom-input">
            <div class="row">
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">{{
                  $t("products.unitName")
                }}</label>
                <input class="form-control" v-model="newMeasurementUnit.name" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="saveNewMeasurementUnit"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("orderChange.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ConfirmModal
    ref="characteristicsDeleteModal"
    :title="$t(`products.deleteCharacterics`)"
    :text="`<ul><li>You will delete ${deleteCharacteristicsObject?.name}<b></li><li>This will delete the ${deleteCharacteristicsObject?.name} values added to products</li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
    :cancel-button="`No, keep ${deleteCharacteristicsObject?.name}`"
    :ok-button="`Yes, delete ${deleteCharacteristicsObject?.name}`"
    v-on:success="deleteCharacteristics"
  />
</template>

<script>
import http from "../../modules/http";
import OneByOneTable from "../lists/OneByOneTable";
import WYSIWYG from "../inputs/WYSIWYG";
import { useStore } from "vuex";
import ConfirmModal from "../modals/ConfirmModal";
import Categories from "./products/Categories";
import CharacteristicsModal from "../modals/CharacteristicsModal";
const bootstrap = require("bootstrap");

export default {
  name: "Companies",
  components: {
    CharacteristicsModal,
    Categories,
    ConfirmModal,
    WYSIWYG,
    OneByOneTable,
  },

  computed: {
    activeLanguages() {
      const store = useStore();
      return store.state.languages;
    },
    activeCountries() {
      const store = useStore();
      return store.state.countries;
    },
  },
  watch: {
    active() {
      if (this.active === "characteristics") {
        http
          .fetch(`/products/${this.type}/characteristics?language=en`)
          .then((data) => {
            this.characteristics = data;
          });
      } else if (this.active === "measurements" && this.measurements === null) {
        http.fetch("/measurements").then((data) => {
          this.measurements = data;
        });
      }
    },
    type() {
      if (this.active === "characteristics") {
        http
          .fetch(`/products/${this.type}/characteristics?language=en`)
          .then((data) => {
            this.characteristics = data;
          });
      }
    },
  },
  methods: {
    moveEvent() {
      let data = this.categoryPosition(this.categories, 0, 0);
      http.fetch(
        `/products/${this.type}/categories/order`,
        { data: data },
        true
      );
    },
    newMeasurementsModal() {
      this.newMeasurementsModalW = new bootstrap.Modal(
        this.$refs.newMeasurementsModal,
        {
          backdrop: "static",
        }
      );
      this.newMeasurementsModalW.show();
    },
    saveNewMeasurement() {
      this.loadAddBtn = true;
      http.fetch("/measurements", this.NewMeasurement).then((data) => {
        this.newMeasurementsModalW.hide();
        this.loadAddBtn = false;
        this.measurements.push(data);
      });
    },
    newMeasurementUnitModal(id) {
      this.newMeasurementUnit.id = id;
      this.newMeasurementUnitModalW = new bootstrap.Modal(
        this.$refs.newMeasurementUnitModal,
        {
          backdrop: "static",
        }
      );
      this.newMeasurementUnitModalW.show();
    },
    saveNewMeasurementUnit() {
      this.loadAddBtn = true;
      http
        .fetch("/measurements/add-unit", this.newMeasurementUnit)
        .then((data) => {
          this.newMeasurementUnitModalW.hide();
          this.loadAddBtn = false;
          this.measurements
            .filter((e) => e.id === this.newMeasurementUnit.id)[0]
            ?.units?.push(data);
        });
    },
    measurementUpdate(row, key, val) {
      http.fetch("/measurements/edit-conversion", {
        id: key.replace("c_", ""),
        line: row.id,
        amount: val,
      });
    },
    measurementAdd(id, key, val) {
      if (this.newMeasurementRow !== undefined) {
        return;
      }
      this.newMeasurementRow = {};
      this.newMeasurementRow[key] = val;
      this.measurements
        .filter((e) => e.id === id)[0]
        ?.conversions.push(this.newMeasurementRow);

      http
        .fetch("/measurements/add-conversion", {
          id: key.replace("c_", ""),
          amount: val,
        })
        .then((data) => {
          this.newMeasurementRow.id = data.id;
          this.newMeasurementRow = undefined;
        });
    },

    changeOption(type, id, enable, row = "code") {
      const method = enable ? "enable" : "disable";
      let data = {};
      data[row] = id;
      http.fetch(`/${type}/${method}`, data, true);
    },
    characteristicsModal(id = false) {
      this.editCharacteristics = id;
      this.$refs.addCharactericsModal.showModal();
    },
    deleteCharacteristicsModal(ch) {
      this.deleteCharacteristicsObject = ch;
      this.$refs.characteristicsDeleteModal.showModal();
    },
    deleteCharacteristics() {
      http
        .fetch(
          `/products/${this.type}/characteristics/delete`,
          { id: this.deleteCharacteristicsObject.id },
          true
        )
        .then(() => {
          let i = this.characteristics.indexOf(
            this.deleteCharacteristicsObject
          );
          if (i > -1) {
            this.characteristics.splice(i, 1);
          }
        });
    },
    characteristicsChange(v) {
      this.characteristics[
        this.characteristics.findIndex((e) => e.id === v.id)
      ] = v;
    },
  },
  data() {
    return {
      loadAddBtn: false,
      active: "categories",
      type: "products",
      deleteCharacteristicsObject: {},
      deleteCategoryObject: {},
      characteristicsRows: [
        {
          name: "ID",
          key: "number",
          type: "id",
          show: false,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Show in variant editor",
          key: "is_variant",
          type: "boolean",
          show: true,
          editable: false,
        },
        {
          name: "Group by in product list",
          key: "main_characteristics",
          type: "boolean",
          show: true,
          editable: false,
        },
        {
          name: "Other characteristics are the same",
          key: "sub_characteristics",
          type: "boolean",
          show: true,
          editable: false,
        },
        {
          name: "Required",
          key: "required",
          type: "boolean",
          show: true,
          editable: false,
        },
        {
          name: "In products",
          key: "in_products",
          type: "boolean",
          show: true,
          editable: false,
        },
        {
          name: "In materials",
          key: "in_materials",
          type: "boolean",
          show: true,
          editable: false,
        },
      ],
      selectedCategory: null,
      selectedLanguage: "en",

      editCharacteristics: false,

      categories: null,
      characteristics: null,

      categoryKey: 0,

      NewMeasurement: {
        name: [],
        description: [],
      },

      newMeasurementUnitModalW: undefined,
      newMeasurementUnit: {
        name: "",
      },

      measurements: null,
      newMeasurementRow: undefined,

      editChategoryVat: false,
    };
  },
};
</script>
